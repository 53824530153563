import React from "react"
import PropTypes from "prop-types"

const Input = ({ inputName, inputLabel, inputType, inputValue, inputOnChange }) => (
    <div className="input">
	    <label htmlFor={inputName}>
	    	{inputLabel}
	    </label>
	    <input
	    	type={inputType}
	    	id={inputName}
	    	name={inputName}
	    	value={inputValue}
	    	onChange={inputOnChange}
	    />
    </div>
)

Input.propTypes = {
	inputName: PropTypes.string,
	inputLabel: PropTypes.string,
	inputType: PropTypes.string,
	inputValue: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number,
	]),
	inputOnChange: PropTypes.func
}

Input.defaultProps = {
	inputName: ``,
	inputLabel: ``,
	inputType: `text`,
	inputValue: ``,
	inputOnChange: () => {}
}

export default Input
