import React from "react"
import PropTypes from "prop-types"

const Panel = ({ children }) => {
    return (
        <main className="panel">
            {children}
        </main>
    )
}

Panel.propTypes = {
    children: PropTypes.node.isRequired,
}

export default Panel
