const inputData = [
    {
        "name": "playerOneFirstName",
        "label": "Player 1 - First Name",
        "type": "text"
    },
    {
        "name": "playerTwoFirstName",
        "label": "Player 2 - First Name",
        "type": "text"
    },
    {
        "name": "minimumPoints",
        "label": "Minimum Points",
        "type": "number"
    },
    {
        "name": "winningScore",
        "label": "Winning Score",
        "type": "number"
    }
]

export default inputData;
