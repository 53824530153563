import React from "react"
import { navigate } from "gatsby"

import Button from "./button"
import Input from "./input"

import inputData from "./data/inputData.js"

class Form extends React.Component {
	constructor() {
		super();
		this.state = {
			inputData: inputData,
			playerOneFirstName: '',
			playerTwoFirstName: '',
			minimumPoints: 0,
			winningScore: 0
		};

		this.handleInputChange = this.handleInputChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
	}

	// TODO number should be a whole positive number, add commas for number over 3 decimals
	handleInputChange(event) {
		const target = event.target;
		const value = target.value;
		const name = target.name;

		this.setState({
			[name]: value
		});
	}

	handleSubmit(event) {
    	event.preventDefault();
    	navigate("/scene/")
  	}

	render() {
		const {inputData} = this.state;

		return (
			<form
	  			className="form"
	  			onSubmit={this.handleSubmit}
	  		>
	  			{
                    inputData.map(item => (
                        <Input
                        	key={item.name}
					  		inputName={item.name}
					  		inputLabel={item.label}
					  		inputType={item.type}
					  		inputValue={this.state[item.name]}
					  		inputOnChange={this.handleInputChange}
					  	/>
                    ))
                }

			    <Button
			    	buttonType="submit"
			    	buttonText="Start New Game"
			    />
		  	</form>
		)
	}
}

export default Form
