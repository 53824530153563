import React from "react"
import PropTypes from "prop-types"

const Button = ({ buttonType, buttonClass, buttonOnClick, buttonText }) => (
    <button
        type={buttonType}
        className={buttonClass}
        onClick={buttonOnClick}
    >
        {buttonText}
    </button>
)

Button.propTypes = {
    buttonType: PropTypes.string,
    buttonClass: PropTypes.string,
    buttonOnClick: PropTypes.func,
    buttonText: PropTypes.string
}

Button.defaultProps = {
    buttonType: `button`,
    buttonClass: `button button--primary`,
    buttonOnClick: () => {},
    buttonText: ``
}

export default Button
