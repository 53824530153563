import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

const Logomark = ({ src }) => (
    <Link
            to="/scene"
        ><div className="logomark">

            <img
                src={src}
                alt="Boxcars App"
                className="logomark__image"
            /></div>
        </Link>

)

Logomark.propTypes = {
    src: PropTypes.string,
}

Logomark.defaultProps = {
    src: ``,
}

export default Logomark
