import React from "react"
// import PropTypes from "prop-types"

import { Link } from "gatsby"

import Panel from "./panel"
import Form from "../components/form"
import Logomark from "../components/logomark"
import SEO from "../components/seo"

import "./styles/_main.scss"

import Logo from "../images/logo-mark.svg"

const LayoutLogin = () => {
    return (
        <div className="layout layout--login">
            <Panel>
                <SEO title="Login" />

                <Logomark
                    src={Logo}
                />

                <h1>Boxcars</h1>
                <p>The dice game you can't get enough of!</p>

                <Form />

                <Link to="/instructions/">Instructions</Link>
            </Panel>
        </div>
    )
}

export default LayoutLogin
